import { getRandomAvatar, getWsConnection } from "/src/utils";


const apiUrl = process.env.VUE_APP_API_URL;
const testInitData = process.env.VUE_APP_TEST_INIT_DATA;


// fetchStatus values
// success, failed, loading

const state = {
    currentUser: null,
    chatList: {},
    loading: true,
    wsConnection: null,
    botName: null
}

const getters = {
    getUserChatList(state) {
        return state.chatList;
    },
    getCurrentUser(state) {
        return state.currentUser;
    },
    getCurrentConnection(state) {
        return state.wsConnection;
    },
    getBotName(state) {
        return state.botName;
    }
}

const mutations = {
    setMiniAppData(state, payload) {
        state.currentUser = payload.user;
        state.loading = false;
        state.botName = payload.botName;

        let chats = {};
        Object.values(payload.chats).forEach((chat) => {
            let unreadCount = 0;
            console.log(chat)
            chat.messages.forEach(message => {
                if (!message.read) {
                    unreadCount += 1;
                }
            });
            chat.unread = unreadCount;
            chats[chat.id] = chat;
        });
        state.chatList = chats;

        const conn = getWsConnection(payload.user.id);
        conn.onmessage = async function (event) {
            console.log("on message event", event)
            if (event.data !== null) {
                const messageData = JSON.parse(event.data);
                const chats = state.chatList;
                const chat = chats[messageData.chat_id];
                console.log("chat: ", chat)
                chat.messages.push(messageData);
            }
        };
        conn.onopen = function (event) {
            console.log("onopen event: ", event);
        };
        conn.onclose = function(e) {
            if (this.$route !== undefined) {
                console.log('Socket is closed. Reconnect will be attempted in 3 second.', e.reason);
                setTimeout(function() {
                    conn = getWsConnection(payload.user.id);
                }, 3000);
            }
        };
        conn.onerror = function(e) {
            console.log('Socket is failed. Reconnect will be attempted in 3 second.', e.reason);
            setTimeout(function() {
                conn = getWsConnection(payload.user.id);
            }, 3000);
        };
        state.wsConnection = conn;
    }
}

const actions = {
    initMiniApp(context) {

        // see examples in examples.js

        let body;
        const tgApp = window.Telegram.WebApp;
        tgApp.ready()
        tgApp.MainButton.setParams({
            text: 'Отправить сообщение',
            is_progress_visible: true,
            is_visible: true
        });

        if (Object.keys(tgApp.themeParams).length === 0) {
            const themeParams = {
                accent_text_color: "#6ab2f2",
                bg_color: "#17212b",
                button_color: "#5288c1",
                button_text_color: "#ffffff",
                destructive_text_color: "#ec3942",
                header_bg_color: "#17212b",
                hint_color: "#708499",
                link_color: "#6ab3f3",
                secondary_bg_color: "#232e3c",
                section_bg_color: "#17212b",
                section_header_text_color: "#6ab3f3",
                subtitle_text_color: "#708499",
                text_color: "#f5f5f5"
            };
    
            document.documentElement.style.setProperty("--tg-theme-bg-color", themeParams.bg_color);
            document.documentElement.style.setProperty("--tg-theme-text-color", themeParams.text_color);
            document.documentElement.style.setProperty("--tg-theme-hint-color", themeParams.hint_color);
            document.documentElement.style.setProperty("--tg-theme-link-color", themeParams.link_color);
            document.documentElement.style.setProperty("--tg-theme-button-color", themeParams.button_color);
            document.documentElement.style.setProperty("--tg-theme-button-text-color", themeParams.button_text_color);
            document.documentElement.style.setProperty("--tg-theme-secondary-bg-color", themeParams.secondary_bg_color);
            document.documentElement.style.setProperty("--tg-theme-header-bg-color", themeParams.header_bg_color);
            document.documentElement.style.setProperty("--tg-theme-accent-text-color", themeParams.accent_text_color);
            document.documentElement.style.setProperty("--tg-theme-section-bg-color", themeParams.section_bg_color);
            document.documentElement.style.setProperty("--tg-theme-section-header-text-color", themeParams.section_header_text_color);
            document.documentElement.style.setProperty("--tg-theme-subtitle-text-color", themeParams.subtitle_text_color);
            document.documentElement.style.setProperty("--tg-theme-destructive-text-color", themeParams.destructive_text_color);
        }

        let urlParams = new URLSearchParams(window.location.search);
        const botName = urlParams.get('botName');

        if (botName === null) {
            alert("Parameter botName expected")
            throw new Error("Parameter botName expected");
        }

        if (testInitData === undefined) {
            body = {
                bot_name: botName,
                init_data: tgApp.initData
            }
        } else {
            body = {
                bot_name: botName,
                init_data: testInitData
            }
        }
        
        fetch(`${apiUrl}/auth/web-app/auth`, {
            method: "POST",
            // mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
        })
          .then(response => response.json())
          .then(result => {
            result["botName"] = botName;
            context.commit("setMiniAppData", result);
          });
    }
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}