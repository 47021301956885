<template>
  <div>
    <router-view/>
  </div>
</template>


<script>
import store from "@/store";

export default {
  mounted() {
    store.dispatch("chat/initMiniApp");
  }
}
</script>
