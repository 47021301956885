<template>
  <div>
    <ChatListTelegram></ChatListTelegram>
  </div>
</template>

<script>
import ChatListTelegram from "@/components/chat/ChatListTelegram";

export default {
  name: 'HomePage',
  components: {
    ChatListTelegram
  }
}
</script>
