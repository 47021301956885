import { createRouter, createWebHistory } from 'vue-router'
import HomePage from "@/pages/common/HomePage";
import ChatDetailTelegram from "@/pages/chat/ChatDetailTelegram";


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
    meta: {
      title: "Yoozi chats"
    }
  },
  {
    path: '/chat/:id/',
    name: 'chat',
    component: ChatDetailTelegram,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
