export const getRandomAvatar = () => {
    const minCeiled = Math.ceil(1);
    const maxFloored = Math.floor(19);
    const num = Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled);
    return `/assets/avatars/${num}.png`;
}

export const getWsConnection = (userId) => {
    const wsUrl = process.env.VUE_APP_WS_URL;
    return new WebSocket(`${wsUrl}/${userId}`);
}
